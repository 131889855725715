import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { TPARef } from '../types/common';
import EditorWrapper from '../EditorWrapper';
import { CHALLENGE_PAGE_ID, PARTICIPANT_PAGE_ID } from '../app-config';
import {
  toArraySettingsParams,
  toBooleanStyleParams,
  toColorStyleParams,
  toFontStyleParams,
  toNumericStyleParams,
} from './migrationHelpers';

async function getPageRef(
  pageId: string,
  editorWrapper: EditorWrapper,
  programPagesOnStage: TPARef[],
) {
  try {
    const challengePageCsm = await editorWrapper.getTpaSection(
      pageId,
      programPagesOnStage,
    );

    return {
      id: challengePageCsm.id,
      type: 'DESKTOP',
    };
  } catch (error) {
    console.error(`Error on getting ${pageId} pageRef: `, error);
  }
  return null;
}

export async function migrateSettingsandStylesParticipantPage(
  flowAPI: EditorScriptFlowAPI,
  editorWrapper: EditorWrapper,
  programPagesOnStage: TPARef[],
): Promise<void> {
  flowAPI.fedops.interactionStarted('migration-styles-settings');
  try {
    const challengePageRef = await getPageRef(
      CHALLENGE_PAGE_ID,
      editorWrapper,
      programPagesOnStage,
    );
    const participantPageRef = await getPageRef(
      PARTICIPANT_PAGE_ID,
      editorWrapper,
      programPagesOnStage,
    );

    if (!challengePageRef || !participantPageRef) {
      throw new Error('Cannot get pagesRef for programs');
    }

    const [styleParams, publicData] = await Promise.all([
      editorWrapper.getStyles(challengePageRef),
      editorWrapper.getPublicData(challengePageRef),
    ]);

    // styles migration
    const fonts = toFontStyleParams(styleParams.fonts);
    const colors = toColorStyleParams(styleParams.colors);
    const numbers = toNumericStyleParams(styleParams.numbers);
    const booleans = toBooleanStyleParams(styleParams.booleans);

    await editorWrapper.setStyles(
      participantPageRef,
      [fonts, colors, numbers, booleans].flat(),
    );
    // enf of styles migration

    // settings migration
    const settings = toArraySettingsParams(publicData.COMPONENT);

    await Promise.all(
      settings.map((setting) =>
        editorWrapper.setPublicData(participantPageRef, setting),
      ),
    );

    flowAPI.fedops.interactionEnded('migration-styles-settings');
    // end of settings migration
  } catch (error) {
    console.error(error);
  }

  return Promise.resolve();
}
